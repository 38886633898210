import { createRouter, createWebHistory  } from 'vue-router'
import Cookies from "js-cookie";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useInfoStore } from "@/store/userInfo";


const router = createRouter({
  history: createWebHistory(),
  routes:[
    {
      path: '/',
      name: 'login',
      component: () => import('@/login/login.vue')
    },
    { //客服
      path: '/index',
      name: 'index',
      component: () => import('@/views/home/index.vue'),
      meta: { requiresUser: true }
    },
    { //管理员
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/admin/index.vue'),
      meta: { requiresAdmin: true },
      redirect: '/overview',
      children: [
        {
          path: '/overview',
          name: 'overview',
          component: () => import('@/views/overview/index.vue'),
          meta: { requiresAdmin: true }
        },
        {
          path: '/custServiceMan',
          name: 'custServiceMan',
          component: () => import('@/views/custServiceMan/index.vue'),
          meta: { requiresAdmin: true }
        },
        {
          path: '/seessionView',
          name: 'seessionView',
          component: () => import('@/views/seessionView/index.vue'),
          meta: { requiresAdmin: true }
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const userStore = useInfoStore(); // 获取存储用户信息的store实例
  const isloginIdentity = userStore.level;
 console.log(isloginIdentity,'isLoggedIn')
  NProgress.start();
  const tokenkey = Cookies.get('token');
  if (tokenkey) {
    if (to.meta.requiresAdmin && isloginIdentity !== 'admin') {
      next('/'); // 或者重定向到无权限页面
    } else if (to.meta.requiresUser && isloginIdentity !== 'support') {
      next('/'); // 或者重定向到无权限页面
    } else {
      next();
    }
  } else{
    if(to.path == '/'){   //判断是否已经进入登录页面path  防止死循环
       next()
    }else{
      next('/')
    }
  }

});
router.afterEach((to,from,next)=>{
  NProgress.done()   //进度条结束
})



export default router
